import React from 'react'
import {AppleAppStoreLink, GooglePlayStoreLink} from "./components/appStoreLinks";
import { useLocation } from 'react-router-dom';

const Home = () => {
    const location = useLocation()

    return (
        <>
            <p><strong>Redirecting you to the ZenSim app...</strong></p>
            <p style={{marginBottom: '40px'}}><a href={location.pathname ? `zensim:/${location.pathname}` : 'zensim://'}>Click here</a> if this doesn't happen automatically.</p>
            <p>Dont have the ZenSim app?</p>
            <div style={{display: 'flex', gap: 8, justifyContent: 'center'}}>
                <GooglePlayStoreLink />
                <AppleAppStoreLink />
            </div>
        </>
    )
}

export default Home
